<!--
 * @Author: Autumn.again
 * @Date: 2023-07-19 09:41:38
 * @LastEditors: Autumn.again
 * @LastEditTime: 2023-07-26 16:52:20
 * @FilePath: \User-Information-System\src\components\CurrentAddressMacao\CurrentAddressMacao.vue
 * Copyright: 2023 by Autumn.again, All Rights Reserved.
-->
<!--
 * @Author: Jacob.Zhang
 * @Description: 现居住地址
-->
<template>
    <el-form ref="ruleForm" :model="value" :rules="rules" class="current-address">
        <el-form-item prop="country">
            <CountrySelect v-model="value.country" />
        </el-form-item>
        <el-form-item v-if="value.country && value.country.includes('中国')" prop="area">
            <CitySelect :country="value.country" v-model="value.area" />
        </el-form-item>
        <!-- 国内地址 -->
        <el-form-item v-if="value.country && value.country.includes('中国')" prop="details">
            <el-input v-model="value.details" clearable auto-complete="off" maxlength="50" :placeholder="placeholder">
                <template v-if="value.area && value.area.length" slot="prepend">{{ value.area.join('') }}</template>
            </el-input>
        </el-form-item>
        <!-- 国外地址 -->
        <el-form-item v-if="value.country && !value.country.includes('中国')" prop="foreign">
            <el-input v-model="value.foreign" clearable auto-complete="off" maxlength="50" :placeholder="placeholder" />
        </el-form-item>
        <!-- <el-form-item v-if="value.country && !value.country.includes('中国')" id="#live_oversea">
            是否居住满一年及以上：
            <el-radio-group v-model="is_live_oversea">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="2">否</el-radio>
            </el-radio-group>
            <div v-if="!is_live_oversea" id="live_oversea_warning" :class="!is_live_oversea ? 'is-error': ''">
                请选择是否居住满一年及以上
            </div>
        </el-form-item> -->
    </el-form>
</template>

<script>
export default {
    name: 'CurrentAddressMacao',
    props: {
        value: {
            type: Object,
            default() {
                return {}
            }
        },
        placeholder: {
            type: String,
            default: "请输入"
        },
        is_live_oversea_year: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            checked1: 0,
            rules: {
                country: [{ required: true, message: "请选择国家", trigger: "change", }],
                area: [{ required: true, message: "请选择城市", trigger: "change", }],
                details: [{ required: true, message: "请输入地址", trigger: "blur", }],
                foreign: [{ required: true, message: "请输入地址", trigger: "blur", }],
            }
        }
    },
    computed: {
        // 海外居住满1年
        is_live_oversea: {
            get () {
                return this.is_live_oversea_year
            },
            set (val) {
                this.$emit('update:is_live_oversea_year', val)
            }
        }
    },
    methods: {
        // async checkInfo() {
        //     try {
        //         await this.$refs.ruleForm.validate()
        //     } catch (error) {
        //         console.log(error)
        //     }
        // },
        // 校验表单
        checkInfo() {
            return new Promise(async(resolve, reject) => {
                try {
                    await this.$refs.ruleForm.validate()
                    // resolve()
                    if (this.value.country && !this.value.country.includes('中国')) {
                        this.is_live_oversea ? resolve() : reject('error')
                    } else {
                        resolve()
                    }
                } catch (error) {
                    reject(error)
                }
            })
        },
        // handleChange(){
        //     if(this.value.country.includes('中国')) this.value.foreign = ''
        //     if(this.value.country && !this.value.country.includes('中国')) this.value.details = ''
        //     this.$emit("input", this.value);
        // }
    }
}
</script>

<style lang="less" scoped>
.current-address {
    display: flex;
    >div:not(:last-of-type) {
        margin-right: 10px;
    }
    .el-select {
        width: 200px;
    }
    >div:nth-of-type(3) {
        flex: 1;
    }
    .el-form-item {
        margin-bottom: 0;
    }
}
    #live_oversea {
        position: relative;

        &_warning {
            position: absolute;
            left: 150px;
            bottom: -20px;
            color: red;
            font-size: 12px;
        }
    }
</style>
