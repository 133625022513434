<!--
 * @Author: filename
 * @Description: 学校全称选择组件
 * @example <SchoolSelect v-model="nationality" />
-->
<template>
    <el-autocomplete
        v-model="nationality"
        :fetch-suggestions="querySearch"
        :placeholder="placeholder"
        @change="handleInput"
        @select="handleSelect"
        @blur="handleBlur">
        <template slot-scope="{ item }">
            <span v-if="item.alias">{{ item.alias }}{{ item.value ? ' / ' : '' }}</span>
            <span v-if="item.value">{{ item.value }}</span>
        </template>
    </el-autocomplete>
</template>

<script>
import { getSchoolList } from "../../api/gifted";
export default {
    name: 'SchoolSelect',
    props: {
        value: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: () => '请输入'
        },
        productId:{
            type:Number,
        }
    },
    data() {
        return {
            nationality: ''
        }
    },
    created() {
        if(this.value) {
            this.nationality = this.value
        }
    },
    methods: {
        createFilter(queryString) {
            return (restaurant) => {
                return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },
        async querySearch(queryString, cb) {
            const { data, code} = await getSchoolList({ name: queryString,productId:this.productId })
            if(code === 200) {
                const restaurants = data
                // const results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
                // 调用 callback 返回建议列表的数据
                cb(restaurants)
            }

        },
        handleInput(v) {
            this.$emit("input", v)
        },
        handleSelect(obj) {
            this.nationality = obj.mainName || obj.value || obj.alias
            this.$emit("input", obj.mainName || obj.value || obj.alias)
        },
        handleBlur() {
            this.$emit("blur", '')
        }
    },
}
</script>

<style scoped lang="less">

</style>
