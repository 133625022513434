import api from './axios';


// 高才新增/编辑信息
export const saveHighTalentInfo = (params) => api.post(`/business/gc/save-family`, params)

// 高才删除家庭成员
export const deleteHighTalentFamily = (params) => api.post(`/business/gc/del-family`, params)

// 高才客户详情
export const getHighTalentDetail = (params) => api.get(`/business/gc/family-detail`, { params })

// 高才初始化家庭信息接口
export const initHighTalentFamily = (params) => api.post(`/business/gc/add-family`, params)

// 获取高才学校
export const getTopSchool = (params) => api.get(`/business/gc/top-school`, { params })

// 高才新增工作经历
export const addOrderWork = (params) => api.post(`/business/gc/add-order-work`, params)

// 高才修改工作经历
export const updateOrderWork = (params) => api.post(`/business/gc/update-order-work`, params)

// 高才删除工作经历
export const deleteOrderWork = (params) => api.post(`/business/gc/delete-order-work`, params)

// 高才新增资产
export const addProperty = (params) => api.post(`/business/gc/add-property`, params)

// 高才修改资产
export const updateProperty = (params) => api.post(`/business/gc/update-property`, params)

// 高才删除资产
export const deleteProperty = (params) => api.post(`/business/gc/delete-property`, params)

// 高才其他 - 工作经验补充信息 - 保存
export const saveGcWorkOther = (params) => api.post(`/business/gc/work-other`, params)

// 高才其他 - 获取工作经验勾选至今的公司枚举
export const getWorkCompany = (params) => api.get(`/business/gc/get-work_enum`, { params })
