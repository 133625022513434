import Vue from 'vue'
import Vuex from 'vuex'
import state from './states'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'
import gifted from './modules/gifted'
import highTalent from './modules/highTalent'
import previewPdfGifted from './modules/previewPdfGifted.js'

Vue.use(Vuex)

export default new Vuex.Store({
    state,
    mutations,
    actions,
    getters,
    modules: {
        gifted, // 优才
        previewPdfGifted, // pdf预览数据存储
        highTalent,  // 高才
    }
})
