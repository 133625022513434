/*
 * @description  公共表单校验
 * @author: Jeff Guo
 * @example
 *      rules: {
            myPhone: [
                {
                    required: true, validator: this.$rules.validate.phone, trigger: 'blur'
                }
            ]
        }
 */

// 手机号
let phone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
// 邮箱
let email = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
// 身份证
let idCard = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
// 护照
let passport = /^([a-zA-z]|[0-9]){5,17}$/;
// 香港身份证
let hkmId = /^([A-Z]\d{6,10}(\(\w{1}\))?)|([A-Z]\d{6,10}(\（\w{1}\）)?)$/;
// 香港身份证，后缀必填
let hkmIdSuffix = /^[A-Z]{1,2}\d{6}([(（][0-9A][)）])$/;
// 香港身份证，后缀非必填
let hkmIdSuffixNotRequired = /^[A-Z]{1,2}\d{6}([(（][0-9A][)）])?$/;
// 港澳通行证
let hkmPass = /^[a-zA-Z0-9]{5,21}$/;
// 英文加数字
let engNum = /^[0-9]+[A-Z]+[0-9A-Z]*|[A-Z]+[0-9]+[0-9A-Z]$/;
// 档案号规则
let fileNum = /^[0-9A-Z-]{15}$/
let validate = (function () {
    return {

        // 邮箱验证规则
        email(rule, value, cb) {
            if (!value) {
                return cb(new Error('邮箱不能为空'));
            }
            if (!email.test(value)) {
                return cb(new Error('邮箱格式不正确'));
            }
            cb();
        },

        // 手机号验证规则 非必填
        phoneRequireFalse(rule, value, cb) {
            if (!value) {
                return cb();
            }
            if (!phone.test(value)) {
                return cb(new Error('请输入正确的手机号码'));
            }
            cb();
        },

        // 手机号验证规则
        phone(rule, value, cb) {
            if (!value) {
                return cb(new Error('手机号不能为空！'));
            }
            if (!phone.test(value)) {
                return cb(new Error('请输入正确的手机号码'));
            }
            cb();
        },

        // 身份证号验证规则
        idCard(rule, value, cb) {
            if (!value) {
                return cb(new Error('身份证不能为空！'));
            }
            if (!idCard.test(value)) {
                return cb(new Error('请输入正确的身份证号码'));
            }
            cb();
        },

        // 护照验证规则
        passport(rule, value, cb) {
            if (!value) {
                return cb(new Error('护照号不能为空！'));
            }
            if (!passport.test(value)) {
                return cb(new Error('请输入正确的护照号码'));
            }
            cb();
        },

        // 香港身份证验证规则
        hkmId(rule, value, cb) {
            if (!value) {
                return cb(new Error('香港身份证不能为空！'));
            }
            if (!hkmId.test(value)) {
                return cb(new Error('请输入正确的香港身份证号码'));
            }
            cb();
        },

        // 香港身份证验证规则-后缀必填
        hkmIdSuffix(rule, value, cb) {
            if (!value) {
                return cb(new Error('香港身份证不能为空！'));
            }
            if (!hkmIdSuffix.test(value)) {
                return cb(new Error('请输入正确的香港身份证号码'));
            }
            cb();
        },

        // 香港身份证验证规则-后缀非必填
        hkmIdSuffixNotRequired(rule, value, cb) {
            if (!value) {
                return cb(new Error('香港身份证不能为空！'));
            }
            if (!hkmIdSuffixNotRequired.test(value)) {
                return cb(new Error('请输入正确的香港身份证号码'));
            }
            cb();
        },


        // 香港身份证验证规则
        macaoId(rule, value, cb) {
            if (!value) {
                return cb(new Error('澳门身份证不能为空！'));
            }
            if (!hkmId.test(value)) {
                return cb(new Error('请输入正确的澳门身份证号码'));
            }
            cb();
        },

        // 港澳通行证验证规则
        hkmPass(rule, value, cb) {
            if (!value) {
                return cb(new Error('港澳通行证不能为空！'));
            }
            if (!hkmPass.test(value)) {
                return cb(new Error('请输入正确的港澳通行证号码'));
            }
            cb();
        },

        // 英文+数字组合规则
        engNum(rule, value, cb) {
            if (!value) {
                return cb(new Error('合同编号不能为空！'));
            }
            if (!engNum.test(value)) {
                return cb(new Error('合同编号格式不正确'));
            }
            cb();
        },

        // 数字+大写字母
        fileNum(rule, value, cb) {
            if (!fileNum.test(value)) {
                return cb(new Error('合同编号格式为4位大写字母+9数字组成！'));
            }
            cb();
        },

        /**
         * 正整数校验
         */
        integerP(rule, value, callback) {
            if (value && !(/^[1-9]\d*$/).test(value)) {
                callback(new Error('只能填写正整数'))
            } else {
                callback()
            }
        },

        /**
         * 负整数校验
         */
        integerN(rule, value, callback) {
            if (value && !(/^-[1-9]\d*$/).test(value)) {
                callback(new Error('只能填写负整数'))
            } else {
                callback()
            }
        },

        /**
         * 英文字符校验
         */
        enText(rule, value, callback) {
            if (value && !(/^[A-Za-z]+$/).test(value)) {
                callback(new Error('只能填写英文字符'))
            } else {
                callback()
            }
        },

        /**
         * 中文字符英文字符校验
         */
        ChEnText(rule, value, callback) {
            if (value && !(/^[A-Za-z0-9]+$/).test(value)) {
                callback(new Error('只能填写数字和英文字符'))
            } else {
                callback()
            }
        },

        /**
         * 中文字符校验
         */
        cnText(rule, value, callback) {
            if (value && (/[^\u4e00-\u9fa5]/).test(value)) {
                callback(new Error('只能填写中文字符'))
            } else {
                callback()
            }
        },

        /**
         * 只能输入英文或者数字
         */
        enOrnunText(rule, value, callback) {
            if (value && !(/^[A-Za-z0-9]+$/).test(value)) {
                callback(new Error('只能填写英文或者数字'))
            } else {
                callback()
            }
        },

        /**
         * 工作经验-职责性质
         */
        dutyInput(rule, value, callback) {
            const isChinese = /^[\u4e00-\u9fa5]+$/;
            if (isChinese.test(value) && value.length > 13) {
                callback(new Error('输入的中文内容不能超过13个字'));
            } else if (!isChinese.test(value) && value.length > 40) {
                callback(new Error('输入内容不能超过40个字'));
            } else {
                callback();
            }
        }
    }

}())
exports.validate = validate
