/**
 * @description 全局pdfbg指令，pdfbg定位瞄点和追加底色
 * @author filename
 * @example <div v-pdfbg:key名称="入参" />
 */
 const vPdfbg = {
    bind(el, binding) {
        el.addEventListener('click', () => {
            const { id } = binding.value
            // 找到dom
            const whereDom = document.querySelector('#'+ id)
            const domNode =  whereDom.querySelector("#" + binding.arg)
            if(whereDom && domNode ){
                domNode.style.background = '#cde1ef'
                setTimeout(() => {
                    domNode.style.background = ''
                }, 1000)
                whereDom.scrollIntoView({
                    block: "start",
                    behavior: "smooth"
                })
            }else{
                console.log('未找到对应id故无法定位和渲染背景色');
            }
        })
    },
    update(el, binding) {
        if (binding.oldValue.value !== binding.value.value) {
            el.classList.add('red')
        } else {
            el.classList.remove("red")
        }
    }
 }

 export default vPdfbg