<!--
 * @Author: Jeff.Guo
 * @Description: 表单卡片容器
 * @example <Container title="基本信息"></Container>
        <Container title="基本信息">
            <div slot="handle">
                <el-button type="primary">保存</el-button>
                <el-button type="primary">取消</el-button>
            </div>
            <div slot="body">
                主体内容
            </div>
        </Container>
-->
<template>
    <div class="card" :style="{width}">
        <div :class="['card-heard', isFillet ? 'upper-right' : '']" :style="{'border-top': isFillet ? '' : 'none'}">
            <div class="title">
                <slot name="title">
                    {{ title }}<template v-if="redFont">（<span class="red-font">{{ redFont }}</span>）</template>
                </slot>
            </div>
            <div class="handle pr-15">
                <slot name="handle"></slot>
            </div>
        </div>
        <div :class="['card-container', isFillet ? 'lower-left' : '']" :style="{ padding }">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Container',
    props: {
        title: { // 标题
            type: String,
            default: "标题"
        },
        width: { // 容器宽度
            type: String,
            default: "960px"
        },
        isFillet: {
            type: Boolean,
            default: () => true
        },
        redFont: {
            type: String,
            default: ''
        },
        padding: {
            type: String,
            default: '15px 15px 0 15px'
        },
    },
    data () {
        return {
        }
    },
    methods: {
        handleChange(v){
            this.$emit("input", v);
        }
    }
};
</script>

<style lang="less" scoped>
.card {
    min-width: 960px;
    margin: 0 auto;
    .card-container {
        // padding: 15px;
        border: 1px solid #dcdfe6;
        border-top: none;
        background: #fff;
    }
    .card-heard {
        padding: 10px 0;
        display: flex;
        position: relative;
        background: #f5f7fa;
        padding-left: 25px;
        color: #000;
        font-size: 14px;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #dcdfe6;
        line-height: 21px;
        .title {
            .red-font {
                color: #ff0000;
            }
        }
        &::before{
            content: "";
            position: absolute;
            width: 4px;
            height: 13px;
            left: 13px;
            top: 50%;
            transform: translateY(-50%);
            background: #3daee6;
        }
        /deep/ .el-button {
            padding: 4px 10px;
        }
    }
    .lower-left {
        border-radius: 0;
    }
    .upper-right{
        border-radius: 4px 4px 0 0;
        background: #409eff;
        color: #fff;
        border: 1px solid #fff;
        &::before{
            content: "";
            position: absolute;
            width: 4px;
            height: 13px;
            left: 13px;
            top: 50%;
            transform: translateY(-50%);
            background: #fff;
        }
    }
}
</style>