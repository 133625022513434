
import { getDetailsServe } from '../../api/gifted'

export default {
    namespaced: true,
    state: {
        user: JSON.parse(localStorage.getItem("user")) || {},
        dataList: [], // 数据集合
        isList: '',
        stepEnd: '',
    },

    mutations: {
        updateData(state, data) {
            state.dataList = data
        },
        // 跟新家庭成员
        updateFamily(state, data) {
            state.dataList[1] = data
        },

        // 登录用户信息
        updateUser(state, data) {
            state.user = data
        },

    },

    getters: {

    },

    actions: {

        // 服务端编辑保存，刷新状态管理的分数
        async editAction({ commit }, data) {
            try {
                const res = await getDetailsServe({ order_id: data.order_id })
                if(res.code === 200) {
                    commit('updateData', res.data.map(item => {
                        return {
                            ...item.data,
                            component: item.component,
                            app: 2,
                            crm_token: data.crm_token
                        }
                    }))
                }
            } catch (error) {
                console.log(error)
            }
        }

    }
}
