
import api from './axios';

//登录
export const login = (params) => api.post(`/client-web/user/login`, params)
export const phoneCode = (params) => api.get(`/client-web/user/sendCode`, {params})

//个人信息表
export const userInfo = (params) => api.get(`/client-web/information/info`, {params})

// 国家联想
export const querySearchCountry  = (params) => api.get(`/backend/country`, {params})

// 个人信息表
export const getInfo = (params) => api.get(`/client-web/information/info`,{params})

// -----  star 优才   ------
//保存基础信息
export const saveInformation = (params) => api.post(`/client-web/information/saveInformation`, params)
//保存家庭成员信息
export const saveSubset = (params) => api.post(`/client-web/information/saveSubset`, params)

//保存背景信息
export const saveQuestion = (params) => api.post(`/client-web/information/saveQuestion`, params)
//保存工作经历
export const saveWork = (params) => api.post(`/client-web/information/saveWork`, params)
//保存居住信息
export const saveResidence = (params) => api.post(`/client-web/information/saveResidence`, params)
//保存学历专业
export const saveQualiAndEduca = (params) => api.post(`/client-web/information/saveQualificationAndEducation`, params)
//提交
export const userSubmit = (params) => api.get(`/client-web/information/submit`, {params})
//模糊查询学校信息
export const schoolList = (params) => api.get(`/client-web/information/schoolList`, {params})
// -----  end 优才   ------

// 服务端跳转到客户端
export const serLogin = (params) => api.get(`/client-web/user/serLogin`, {params})
//获取订单产品详情
export const getProduct = (params) => api.get(`/business/expert/product`, {params})

// 获取oss接口
export const getTempUrl = (params) => api.get(`/business/common-logic/access-url`, params)
