/*
 * @FilePath: /User-Information-System/src/api/axios.js
 * @Description:
 */
import axios from "axios";
// import router from "@/router/index";
import {
    MessageBox,
    Message
} from "element-ui";
import store from '../store'
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.put['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.delete['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.patch['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';

const api = axios.create({
    baseURL: '',
    timeout: 60000
})
api.interceptors.request.use(
    config => {
        // 由自己的接口传入token，调用其它服务（backend）需要自己的token
        let configToken = config?.config?.Token
        let token = configToken ? configToken : store.state.token;
        config.headers["Authorization"] = token;
        config.headers["Token"] = token;
        config.headers["system-name"] = window.top != window.self ? 'CRM-server':'USER_INFO';
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

api.interceptors.response.use(
    response => {
        if (response.status === 200 && response.data.code == 200) {
            return response.data;
        } else if (response.status === 200 && (response.data.code == 401 || response.data.code == 403)) {
            // localStorage.clear()
            // 若登录态失效的页面是服务端嵌入页，则做提示。(成功情况下的401)
            if(window.location.pathname.toLowerCase().includes('editentrance')) {
                return msg = `${msg}（请刷新页面）`
            }
            MessageBox.alert("身份验证失败,请重新登陆", "报错了", {
                confirmButtonText: "确定",
                type: "error"
            }).finally(() => {
                return window.location.href = `${process.env.VUE_APP_CLIENT_URL}/#/Login`
            })
        } else {
            Message(response.data.msg);
            return response.data;
        }
    },
    error => {
        const res = error.response
        let msg = ""
        if (res.status === 400) {
            msg = "400 Bad"
        } else if (res.status === 500) {
            msg = res.data.msg || "服务器内部错误"
            // 若登录态失效的页面是服务端嵌入页，则做提示。
            if(res.data.code === 401 && window.location.pathname.toLowerCase().includes('editentrance')) {
                return msg = `${msg}（请刷新页面）`
            }
            // 如果body中code为401表示登录态失效
            if (res.data.code === 401) return window.location.href = `${process.env.VUE_APP_CLIENT_URL}/#/Login`
        } else {
            msg = "未知错误"
        }

        MessageBox.alert(msg, "报错了", {
            confirmButtonText: "确定",
            type: "error"
        });
        return Promise.reject(error)
    }
)

export default api
