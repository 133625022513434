/**
 * @description 全局dialog弹窗拖拽指令
 * @author Jeff.Guo
 * @example <el-dialog title="弹窗" v-dialogDrag>
 */

/* eslint-disable */
export default {

    bind(el, binding, _vnode, oldVnode) {
        const dialogHeaderElement = el.querySelector('.el-dialog__header')
        const dragDom = el.querySelector('.el-dialog')
        dialogHeaderElement.style.cursor = 'move'

        const sty = dragDom.currentStyle || window.getComputedStyle(dragDom, null)

        dialogHeaderElement.onmousedown = (e) => {
            // 鼠标按下，计算当前元素距离可视区的距离
            const disX = e.clientX - dialogHeaderElement.offsetLeft
            const disY = e.clientY - dialogHeaderElement.offsetTop

            // 获取到的值带px 正则匹配替换
            let styL, styT;

            // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
            
            
            if (sty.left.includes('%')) {
                styL = +document.body.clientWidth * (+sty.left.replace(/\%/g, '') / 100)
                styT = +document.body.clientHeight * (+sty.top.replace(/\%/g, '') / 100)
            } else {
                styL = +sty.left.replace(/\px/g, '')
                styT = +sty.top.replace(/\px/g, '')
            }
            

            document.onmousemove = function (e) {
                // 通过事件委托，计算移动的距离
                const l = e.clientX - disX
                const t = e.clientY - disY

                // 移动当前元素
                dragDom.style.left = `${l + styL}px`
                dragDom.style.top = `${t + styT}px`

                // 将此时的位置传出去
                // binding.value({x:e.pageX,y:e.pageY})
            }

            document.onmouseup = function (e) {
                document.onmousemove = null
                document.onmouseup = null
            }
        }
    }
}
/* eslint-disable */