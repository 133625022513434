<template>
    <div id="app">
        <router-view />
        <div v-if="showQr" v-drag class="suspension">
            <div class="model">
                <div class="item top">
                    <img src="~@/assets/img/xcx_icon.png" class="wechat-img" alt="小程序太阳码" />
                    <div class="txt">小程序</div>
                    <div class="txt">操作更便捷</div>
                    <div class="wechat-qr-model">
                        <img class="qr-code" src="~@/assets/img/sun_qr_detail.png" alt="小程序太阳码" />
                        <div class="txt1">扫码登录小程序</div>
                        <div class="txt2">填写上传更便捷</div>
                    </div>
                </div>
                <div class="item fot">
                    <img src="~@/assets/img/wechat.png" class="wechat-img" alt="公众号二维码" />
                    <div class="txt">公众号</div>
                    <div class="txt">通知更及时</div>
                    <div class="wechat-qr-model">
                        <img class="qr-code" src="~@/assets/img/gzh_detail.png" alt="公众号二维码" />
                        <div class="txt1">扫码关注公众号</div>
                        <div class="txt2">消息通知更及时</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            envs: process.env.VUE_APP_ENV === 'production',
            showQr: parent === window // 该项目是否有父项目
        }
    },
}
</script>
<style lang="less">
    @import "~@/assets/css/common.less";
    .suspension {
        width: 79px;
        height: 228px;
        border-radius: 80px;
        border: 1px solid rgba(130, 131, 149, 0.20);
        background: #FFF;
        box-shadow: 0px 3px 18px 0px rgba(15, 62, 174, 0.10);
        position: absolute;
        top: 70%;
        right: 0;
        transform: translate(0, -50%);
        cursor: move;
        user-select: none;
        z-index: 99;
        .model {
            width: 79px;
            height: 228px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 20px 11px;
            box-sizing: border-box;
            .item {
                display: flex;
                flex-direction: column;
                align-items: center;
                cursor: default;
                position: relative;
                .wechat-img {
                    width: 40px;
                    height: 40px;
                    margin-bottom: 4px;
                }
                .txt {
                    color: #606266;
                    text-align: center;
                    font-size: 11px;
                    font-weight: 400;
                }
                .wechat-qr-model {
                    display: none;
                    width: 172px;
                    height: 227px;
                    border-radius: 6px;
                    border: 1px solid rgba(130, 131, 149, 0.20);
                    background: #FFF;
                    box-shadow: 0px 3px 18px 0px rgba(15, 62, 174, 0.10);
                    padding: 16px;
                    box-sizing: border-box;
                    .qr-code {
                        width: 140px;
                        height: 140px;
                        margin-bottom: 4px;
                    }
                    .txt1 {
                        color: #000;
                        text-align: center;
                        font-size: 16px;
                        font-weight: 400;
                        margin-bottom: 5px;
                    }
                    .txt2 {
                        color: #606266;
                        text-align: center;
                        font-size: 14px;
                        font-weight: 400;
                    }
                }
            }
            .top {
                position: relative;
                &::after {
                    content: '';
                    width: 36px;
                    height: 1px;
                    background: #E6E6E6;
                    position: absolute;
                    top: 94px;
                    left: 11px;
                }
                &:hover {
                    .txt {
                        color: #409EFF;
                    }
                    .wechat-qr-model {
                        display: block;
                        position: absolute;
                        left: -190px;
                        top: -18px;
                    }
                }
            }
            .fot {
                &:hover {
                    .txt {
                        color: #409EFF;
                    }
                    .wechat-qr-model {
                        display: block;
                        position: absolute;
                        left: -190px;
                        top: -130px;
                    }
                }
            }
        }
    }
</style>
