<!--
 * @Author: Jacob.Zhang
 * @Description: 国籍选择组件
 * @example <CountrySelect v-model="nationality" />
-->
<template>
    <el-select
        v-model="nationality"
        clearable filterable remote reserve-keyword :placeholder="placeholder"
        :remote-method="queryCountryAsync" :loading="isLoading"
        @change="handleSelect"
    >
        <el-option v-for="item in countryOption" :key="item.name_en" :label="item.name_cn + '-' + item.name_en" :value="item.name_cn + '-' + item.name_en" />
    </el-select>
</template>

<script>
import { getCountries } from "../../api/gifted";
export default {
    name: 'CountrySelect',
    props: {
        value: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '请输入匹配国家及地区',
        },
        index: {
            type: Number,
            default: 0,
        },
    },
    created() {
        if(this.value) {
            this.countryOption.push({
                name_cn: this.value.split('-')[0],
                name_en: this.value.slice(this.value.indexOf('-') + 1),
            })
            this.nationality = this.value
        }
    },
    data() {
        return {
            isLoading: false,
            nationality: '',
            countryOption: [], // 国家选项
        }
    },
    methods: {
        // 获取国籍
        async queryCountryAsync(keyword) {
            try {
                this.isLoading = true
                const res = await getCountries({ keyword })
                if(res.code === 200) {
                    this.countryOption = res.data
                }
            } catch (error) {
                console.log(error)
            } finally {
                this.isLoading = false
            }
        },
        handleSelect(v) {
            this.$emit("input", v, this.index)
        },
    },
}
</script>

<style scoped lang="less">

</style>