/**
 * @description 批量注册全局组件
 * @author Jeff.Guo
 */

import CitySelect from './CitySelect/CitySelect';
import Container from './Container/Container'; // 表单容器
import AddressSelect from './AddressSelect/AddressSelect';
import CountrySelect from './CountrySelect/CountrySelect'; // 国籍选择
import CertificateUpload from './CertificateUpload/CertificateUpload';
import UploadFile from './UploadFile/UploadFile';
import CurrentAddress from './CurrentAddress/CurrentAddress';
import CurrentAddressMacao from './CurrentAddressMacao/CurrentAddressMacao';
import SchoolSelect from './SchoolSelect/SchoolSelect.vue'

const component = {
    CitySelect,
    Container,
    AddressSelect,
    CountrySelect,
    CertificateUpload,
    UploadFile,
    CurrentAddress,
    CurrentAddressMacao,
    SchoolSelect
};
// 批量注册组件
export default {
    install(Vue) {
        Object.keys(component).forEach((key) => {
            Vue.component(key, component[key]);
        });
    }
};
