import {
    userInfo
} from "@/api/";

export default {

    // 获取用户信息表数据
    getUserFrom({
        commit
    }, orderId) {
        commit('SetUserFrom', 999);
        userInfo({
            orderId: orderId || 5953  // 临时调试用
        }).then(res => {
            if (res.code === 200) {
                commit('SetUserFrom', res.data);  //  存过去
            }
        });
    }

};