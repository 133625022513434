export default {
  inserted(el) {
    let gap = 0; // 可设置吸附距离
    let parent = el.offsetParent;
    el.onmousedown = function (event) {
      event.preventDefault(); // 阻止默认事件
      var eleEvent = event;
      var x = eleEvent.clientX - el.offsetLeft;
      var y = eleEvent.clientY - el.offsetTop;
      var left = 0;
      var top = 0;
      document.onmousemove = function (eve) {
        left = eve.clientX - x;
        top = eve.clientY - y;

        // 左吸附
        if (left <= gap) {
          left = 0;
        }

        //右吸附
        if (left >= parent.offsetWidth - el.offsetWidth - gap) {
          left = parent.offsetWidth - el.offsetWidth;
        }

        // 上吸附
        if (top <= gap) {
          top = 0;
        }

        // 下吸附
        if (top >= parent.offsetHeight - el.offsetHeight - gap) {
          top = parent.offsetHeight - el.offsetHeight;
        }

        el.style.left = left + "px";
        el.style.top = top + "px";
      };
      document.onmouseup = function () {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    };
  },
};
