<!--
 * @Author: Jacob.Zhang
 * @Description: 证件上传
-->
<template>
    <el-form ref="certificates" :model="certificates" class="certificate-upload">
        <!-- 扫码 -->
        <!-- <div class="scan-code">
            <div class="code">
                <img src="@/assets/img/test_code.png">
            </div>
            <div class="code-text">
                <p>如您的手机上有证件照片，您也可以扫码在手机上进行上传操作，我们将会把您的证件回显到PC端。</p>
            </div>
        </div> -->

        <!-- 上传 -->
        <el-row :gutter="10" class="image-upload">
            <el-col :span="48" class="upload-box">
                <el-form-item label="身份证头像面"
                    prop="cn_identity_card.img_front"
                >
                    <!-- :rules="nationality.includes('中国-') ? [{ required: true, message: '请上传', trigger: 'change', }] : [{ required: false}]" -->
                    <div v-if="certificates.cn_identity_card.img_front" class="image-box" :style="`backgroundImage: url(${$utils.toOss(certificates.cn_identity_card.img_front)});`"></div>
                    <div v-else class="image-box" style="backgroundImage: url(https://upload-cdn.galaxy-immi.com/public/img/usersystem/id_front.png);"></div>
                    <div>
                        <UploadFile
                            :button-text="certificates.cn_identity_card.img_front ? '重新上传' : '上传识别'"
                            :button-center="true"
                            :format-tip="['png', 'jpeg', 'jpg', 'gif']"
                            :need-success="false"
                            @fileList="changeIdFront"
                        />
                    </div>
                </el-form-item>
            </el-col>
            <el-col :span="48" class="upload-box">
                <el-form-item label="身份证国徽面"
                    prop="cn_identity_card.img_back"
                >
                    <div v-if="certificates.cn_identity_card.img_back" class="image-box" :style="`backgroundImage: url(${$utils.toOss(certificates.cn_identity_card.img_back)});`"></div>
                    <div v-else class="image-box" style="backgroundImage: url(https://upload-cdn.galaxy-immi.com/public/img/usersystem/id_back.png);"></div>
                    <div>
                        <UploadFile
                            :button-text="certificates.cn_identity_card.img_back ? '重新上传' : '上传识别'"
                            :button-center="true"
                            :format-tip="['png', 'jpeg', 'jpg', 'gif']"
                            :need-success="false"
                            @fileList="changeIdBack"
                        />
                    </div>
                </el-form-item>
            </el-col>
            <el-col v-if="isHandled === 1" :span="48" class="upload-box">
                <el-form-item label="港澳通行证正面" prop="hk_macao_pass.img_front">
                    <div v-if="certificates.hk_macao_pass.img_front" class="image-box" :style="`backgroundImage: url(${$utils.toOss(certificates.hk_macao_pass.img_front)});`"></div>
                    <div v-else class="image-box" style="backgroundImage: url(https://upload-cdn.galaxy-immi.com/public/img/usersystem/hkm_front.png);"></div>
                    <div>
                        <UploadFile
                            :button-text="certificates.hk_macao_pass.img_front ? '重新上传' : '上传识别'"
                            :button-center="true"
                            :format-tip="['png', 'jpeg', 'jpg', 'gif']"
                            :need-success="false"
                            @fileList="changeHkmFront"
                        />
                    </div>
                </el-form-item>
            </el-col>
            <el-col v-if="isHandled === 1" :span="48" class="upload-box">
                <el-form-item label="港澳通行证反面" prop="hk_macao_pass.img_back">
                    <div v-if="certificates.hk_macao_pass.img_back" class="image-box" :style="`backgroundImage: url(${$utils.toOss(certificates.hk_macao_pass.img_back)});`"></div>
                    <div v-else class="image-box" style="backgroundImage: url(https://upload-cdn.galaxy-immi.com/public/img/usersystem/hkm_back.png);"></div>
                    <div>
                        <UploadFile
                            :button-text="certificates.hk_macao_pass.img_back ? '重新上传' : '上传识别'"
                            :button-center="true"
                            :format-tip="['png', 'jpeg', 'jpg', 'gif']"
                            :need-success="false"
                            @fileList="changeHkmBack"
                        />
                    </div>
                </el-form-item>
            </el-col>
            <!-- 【ID1004281】997/981 优化 - 暂时隐藏护照的上传模块 -->
            <!-- <el-col :span="48" class="upload-box">
                <el-form-item label="护照"
                    prop="passport.img_front"
                >
                    <div v-if="certificates.passport.img_front" class="image-box" :style="`backgroundImage: url(${certificates.passport.img_front});`"></div>
                    <div v-else class="image-box" style="backgroundImage: url(https://upload-cdn.galaxy-immi.com/public/img/usersystem/passport.png);"></div>
                    <div>
                        <UploadFile
                            :button-text="certificates.passport.img_front ? '重新上传' : '上传识别'"
                            :button-center="true"
                            :format-tip="['png', 'jpeg', 'jpg', 'gif']"
                            :need-success="false"
                            @fileList="changePassort"
                        />
                    </div>
                </el-form-item>
            </el-col> -->
        </el-row>
    </el-form>
</template>

<script>
import UploadFile from '../UploadFile/UploadFile.vue'
import { getIdCard, getHkmCard, getPassort } from '../../api/gifted'
export default {
    components: { UploadFile },
    props: {
        nationality: {
            type: String,
            default: '',
        },
        isHandled: {
            type: [Number, String],
            default: null,
        },
        certificates: {
            type: Object,
            default() {
                return {}
            },
        },
    },
    data() {
        return {
            // rules: {
            //     'hk_macao_pass.img_front': [{ required: true, message: '请上传', trigger: 'change', }],
            //     'hk_macao_pass.img_back': [{ required: true, message: '请上传', trigger: 'change', }],
            // },
        }
    },
    methods: {
        // 身份证正面
        async changeIdFront(list) {
            if(list.length === 0) return
            if(list[0].fileSize/1024/1024 > 20) return this.$message.error('请上传小于20M的图片')
            // 若上传的图片大于4M对图片进行压缩。但接受压缩的图片大小为20M
            this.getIdInfo((list[0].fileSize/1024/1024) < 4 ? list[0].fileUrl : `${list[0].fileUrl}`, 'front')
        },
        // 身份证反面
        changeIdBack(list) {
            if(list.length === 0) return
            if(list[0].fileSize/1024/1024 > 20) return this.$message.error('请上传小于20M的图片')
            this.getIdInfo((list[0].fileSize/1024/1024) < 4 ? list[0].fileUrl : `${list[0].fileUrl}`, 'back')
        },
        // 获取身份证信息
        async getIdInfo(url, id_card_side) {
            try {
                this.isLoading = true
                const { code, data } = await getIdCard({ url: this.$utils.toOss(url), id_card_side })
                let isRight = data.words_result && data.image_status && data.image_status === 'normal'
                if(code === 200 && isRight) {
                    switch (id_card_side) {
                        // 正面与反面返回不同的数据
                        case 'front':
                            this.$emit('handleInfo', {
                                type: 'cn_identity_card',
                                id_card_side,
                                url,
                                // address: data.words_result['住址'].words,
                                number: data.words_result['公民身份号码'].words,
                                birthday: this.$dayjs(data.words_result['出生'].words).format('YYYY-MM-DD'),
                                username: data.words_result['姓名'].words,
                                sex: this.formatSex(data.words_result['性别'].words),
                            })
                            break;
                        case 'back':
                            this.$emit('handleInfo', {
                                type: 'cn_identity_card',
                                id_card_side,
                                url,
                                issue_at: data.words_result['签发机关'].words,
                                issue_date_start_at: this.$dayjs(data.words_result['签发日期'].words).format('YYYY-MM-DD'),
                                issue_date_end_at: data.words_result['失效日期'].words === '长期' ? '8888-01-01' : this.$dayjs(data.words_result['失效日期'].words).format('YYYY-MM-DD'),
                            })
                        default:
                            break;
                    }
                    this.certificates.cn_identity_card[`img_${id_card_side}`] = url
                    this.$message.success('您已上传成功，已对您的证件信息进行识别，并填入以下表中')
                } else if(code === 200 && data.error_code && data.error_code === 216202) {
                    this.$message.error('请上传小于20M的图片')
                } else {
                    // 无法识别仍然返回url
                    this.$emit('handleInfo', {
                        type: 'cn_identity_card',
                        id_card_side,
                        url,
                    })
                    this.certificates.cn_identity_card[`img_${id_card_side}`] = url
                    this.$message.error('识别失败，请上传清晰且无遮挡的证件')
                }
            } catch (error) {
                console.log(error)
            } finally {
                this.isLoading = false
            }
        },
        // 港澳通行证正面
        changeHkmFront(list) {
            if(list.length === 0) return
            if(list[0].fileSize/1024/1024 > 20) return this.$message.error('请上传小于20M的图片')
            this.getHkmInfo((list[0].fileSize/1024/1024) < 4 ? list[0].fileUrl : `${list[0].fileUrl}`, 'front')
        },
        // 港澳通行证反面
        changeHkmBack(list) {
            if(list.length === 0) return
            if(list[0].fileSize/1024/1024 > 20) return this.$message.error('请上传小于20M的图片')
            this.getHkmInfo((list[0].fileSize/1024/1024) < 4 ? list[0].fileUrl : `${list[0].fileUrl}`, 'back')
        },
        async getHkmInfo(url, id_card_side) {
            if(id_card_side === 'back') {
                // 反面不做识别（非有效信息）
                this.$emit('handleInfo', {
                    type: 'hk_macao_pass',
                    id_card_side: 'back',
                    url
                })
                this.certificates.hk_macao_pass[`img_${id_card_side}`] = url
                this.$message.success('您已上传成功，已对您的证件信息进行识别，并填入以下表中')
                return
            }
            try {
                this.isLoading = true
                const { code, data } = await getHkmCard({ url: this.$utils.toOss(url) })
                // ID1020805需求只做提示，不做强校验
                // 对OCR返回的字段进行强校验
                // 强校验是否是港澳通行证（校验了非填充项，如姓名、性别、起止时间等），若不通过 则不做填充。
                // 强校验每一项的原因：OCR识别其他类型的证件时会填充进对应的字段（不能以识别通过并且数据类型也通过就给它填充）
                // let isRight = data.words_result && data.words_result.NameChn.words && data.words_result.Sex.words && /^[a-zA-Z0-9]{5,21}$/.test(data.words_result.CardNum.words)
                // 起止时间的校验
                // let checkDate = !Array.isArray(data.words_result.ValidDate) && (data.words_result.ValidDate.words.length === 17) && (data.words_result.ValidDate.words.indexOf('-') === 8)
                let message = []
                let info = {}
                if(code === 200 && data.words_result) {
                    // 完全识别不到
                    if (!data.words_result.NameChn.words && !data.words_result.Sex.words && Array.isArray(data.words_result.ValidDate)) {
                        this.$emit('handleInfo', {
                            type: 'hk_macao_pass',
                            id_card_side: 'front',
                            url,
                        })
                        this.certificates.hk_macao_pass[`img_${id_card_side}`] = url
                        this.$message.error('识别失败，请上传清晰且无遮挡的证件')
                    } else {
                        if (!data.words_result.NameChn.words) {
                            message.push('姓名')
                        }
                        if (!data.words_result.Sex.words) {
                            message.push('性别')
                        }
                        if (!/^[a-zA-Z0-9]{5,21}$/.test(data.words_result.CardNum.words)) {
                            message.push('证件号码')
                        } else {
                            info.number = data.words_result.CardNum.words
                        }
                        if (Array.isArray(data.words_result.ValidDate)) {
                            message.push('证件日期')
                        }
                        if (!Array.isArray(data.words_result.ValidDate) && (data.words_result.ValidDate.words.split('-')[0].length !== 8 && data.words_result.ValidDate.words.split('-')[1].length === 8)) {
                            message.push('签发日期')
                            info.issue_date_end_at = this.$dayjs(data.words_result.ValidDate.words.split('-')[1]).format('YYYY-MM-DD')
                        } else if (!Array.isArray(data.words_result.ValidDate) && (data.words_result.ValidDate.words.split('-')[0].length === 8 && data.words_result.ValidDate.words.split('-')[1].length !== 8)) {
                            message.push('届满日期')
                            info.issue_date_start_at = this.$dayjs(data.words_result.ValidDate.words.split('-')[0]).format('YYYY-MM-DD')
                        } else if (!Array.isArray(data.words_result.ValidDate) && (data.words_result.ValidDate.words.length === 17) && (data.words_result.ValidDate.words.indexOf('-') === 8)) {
                            info.issue_date_start_at = this.$dayjs(data.words_result.ValidDate.words.split('-')[0]).format('YYYY-MM-DD')
                            info.issue_date_end_at = this.$dayjs(data.words_result.ValidDate.words.split('-')[1]).format('YYYY-MM-DD')
                        }
                        this.certificates.hk_macao_pass[`img_${id_card_side}`] = url
                        // 校验有错误仍返回路径
                        if (message.length > 0) {
                            this.$emit('handleInfo', {
                                type: 'hk_macao_pass',
                                id_card_side: 'front',
                                url,
                                issue_at: data.words_result.Address.words,
                                ...info
                            })
                            this.$message.error('部分识别不成功，' + message.join(',') + '请手动填写')
                        } else {
                            this.$emit('handleInfo', {
                                type: 'hk_macao_pass',
                                id_card_side: 'front',
                                url,
                                number: data.words_result.CardNum.words,
                                issue_at: data.words_result.Address.words,
                                issue_date_start_at: this.$dayjs(data.words_result.ValidDate.words.split('-')[0]).format('YYYY-MM-DD'),
                                issue_date_end_at: this.$dayjs(data.words_result.ValidDate.words.split('-')[1]).format('YYYY-MM-DD'),
                            })
                            // 通过校验
                            this.$message.success('您已上传成功，已对您的证件信息进行识别，并填入以下表中')
                        }
                    }

                    // this.$emit('handleInfo', {
                    //     type: 'hk_macao_pass',
                    //     id_card_side: 'front',
                    //     url,
                    //     number: data.words_result.CardNum.words,
                    //     issue_at: data.words_result.Address.words,
                    //     issue_date_start_at: this.$dayjs(data.words_result.ValidDate.words.split('-')[0]).format('YYYY-MM-DD'),
                    //     issue_date_end_at: this.$dayjs(data.words_result.ValidDate.words.split('-')[1]).format('YYYY-MM-DD'),
                    // })
                    // this.certificates.hk_macao_pass[`img_${id_card_side}`] = url
                    // this.$message.success('您已上传成功，已对您的证件信息进行识别，并填入以下表中')
                } else if(code === 200 && data.error_code && data.error_code === 216202) {
                    this.$message.error('请上传小于20M的图片')
                } else {
                    this.$emit('handleInfo', {
                        type: 'hk_macao_pass',
                        id_card_side: 'front',
                        url,
                    })
                    this.certificates.hk_macao_pass[`img_${id_card_side}`] = url
                    this.$message.error('识别失败，请上传清晰且无遮挡的证件')
                }
            } catch (error) {
                console.log(error)
            } finally {
                this.isLoading = false
            }
        },
        // 护照
        changePassort(list) {
            if(list.length === 0) return
            if(list[0].fileSize/1024/1024 > 20) return this.$message.error('请上传小于20M的图片')
            this.getPassort((list[0].fileSize/1024/1024) < 4 ? list[0].fileUrl : `${list[0].fileUrl}`, 'front')
        },
        async getPassort(url, id_card_side) {
            try {
                this.isLoading = false
                const { code, data } = await getPassort({ url: this.$utils.toOss(url) })
                if(code === 200 && data.words_result) {
                    this.$emit('handleInfo', {
                        type: 'passport',
                        id_card_side: 'front',
                        url,
                        number: data.words_result['护照号码'].words,
                        issue_at: data.words_result['签发机关'].words,
                        issue_date_start_at: this.$dayjs(data.words_result['签发日期'].words).format('YYYY-MM-DD'),
                        issue_date_end_at: this.$dayjs(data.words_result['有效期至'].words).format('YYYY-MM-DD'),
                        username: data.words_result['姓名'].words,
                        birthday: this.$dayjs(data.words_result['生日'].words).format('YYYY-MM-DD'),
                        sex: this.formatSex(data.words_result['性别'].words),
                    })
                    this.certificates.passport[`img_${id_card_side}`] = url
                    this.$message.success('您已上传成功，已对您的证件信息进行识别，并填入以下表中')
                } else {
                    this.$emit('handleInfo', {
                        type: 'passport',
                        id_card_side: 'front',
                        url
                    })
                    this.certificates.passport[`img_${id_card_side}`] = url
                    this.$message.error('识别失败，请上传清晰且无遮挡的证件')
                }
            } catch (error) {
                console.log(error)
            } finally {
                this.isLoading = false
            }
        },
        formatSex(sexStr) {
            if(sexStr.includes('男')) {
                return 1
            } else if(sexStr.includes('女')) {
                return 2
            } else {
                return 0
            }
        },
        // 校验表单
        checkInfo() {
            return new Promise(async(resolve, reject) => {
                try {
                    await this.$refs.certificates.validate()
                    resolve()
                } catch (error) {
                    reject(error)
                }
            })
        },
    },
}
</script>

<style lang="less" scoped>
.certificate-upload {
    font-size: 14px;
    color: #606266;
    .scan-code {
        border: 1px solid #E5E6F4;
        padding: 30px;
        margin-bottom: 20px;
        display: flex;
        .code {
            width: 140px;
            margin-right: 30px;
            img {
                width: 100%;
                vertical-align: middle;
            }
        }
    }
    .el-row.image-upload {
        display: flex;
        justify-content: space-between;
        .el-col-48.upload-box {
            width: 20%;
            text-align: center;
            .image-box {
                height: 115px;
                border: 1px solid #E5E6F4;
                overflow: hidden;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            /deep/ .el-form-item {
                margin-left: 0;
                .el-form-item__label {
                    float: none;
                    padding-right: 0; // 不校验时打开
                }
                .el-form-item__content {
                    >div + div {
                        margin-top: 12px;
                    }
                }
            }
            /deep/ .el-form-item__error {
                margin-top: 8px !important;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
}
</style>
