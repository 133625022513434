export default {
    OSS_BASE_URL: "https://upload-cdn.galaxy-immi.com/",
    // 婚姻状况
    MARRIED: [
        { value: 1, label: '已婚' },
        { value: 2, label: '未婚' },
        { value: 3, label: '离婚' },
        { value: 4, label: '分居' },
        { value: 5, label: '丧偶' },
    ],
    // 澳门优才-婚姻状况
    MARITAL_STATUS_OPTIONS: {
        single: {
            value: 2,
            label: '未婚'
        },
        married: {
            value: 1,
            label: '已婚'
        },
        commonLawMarriage: {
            value: 6,
            label: '事实婚姻'
        },
        divorced: {
            value: 3,
            label: '离婚'
        },
        widowed: {
            value: 7,
            label: '鳏寡'
        }
    }
}
