import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store"
Vue.use(VueRouter)
import { sendMonitorData } from "../utils/utils"

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
}

const routes = [
    // 客户端
    {
        path: "/",
        name: "Layout",
        component: () => import("../views/Layout/Layout.vue"),
        children: [
            // 服务端跳转接收页
            {
                path: "/Auth/:key",
                name: "Auth",
                component: () => import("../views/Auth.vue")
            },
            // 旧客户端验证码登录后的中转页
            {
                path: "/MiddleLogin/:phoneNum/:code",
                name: "MiddleLogin",
                component: () => import("../views/MiddleLogin.vue")
            },
            // 随迁人员提交成功跳转至首页中转页
            {
                path: "/JumpPage",
                name: "JumpPage",
                component: () => import("../views/JumpPage.vue")
            },
            // 表单
            {
                path: "/UserInfo",
                name: "UserInfo",
                component: () => import("../views/UserInfo/UserInfo.vue"),
                children: [
                    // 优才
                    {
                        path: "Gifted/:id",
                        name: "Gifted",
                        component: () => import("../views/UserInfo/Gifted/Gifted.vue")
                    },
                    // 澳门优才
                    {
                        path: "MacaoGifted/:id",
                        name: "MacaoGifted",
                        component: () => import("../views/UserInfo/MacaoGifted/MacaoGifted.vue")
                    },
                    // 专才
                    {
                        path: "Expert/:id",
                        name: "Expert",
                        component: () => import("../views/UserInfo/Expert/Expert.vue")
                    },
                    // 留学
                    {
                        path: "Study/:id",
                        name: "Study",
                        component: () => import("../views/UserInfo/Study/Study.vue")
                    },
                    // 随迁人员
                    {
                        path: "Transfer/:id",
                        name: "Transfer",
                        component: () => import("../views/UserInfo/Transfer/Transfer.vue")
                    },
                ]
            },
            // 个人信息表 - 所有信息
            {
                path: "/Info",
                name: "Info",
                component: () => import("../views/Info/Info.vue"),
                children: [
                    // 优才
                    {
                        path: "Gifted/:id",
                        name: "GiftedDetails",
                        component: () => import("../views/Info/Gifted/Gifted.vue")
                    },
                    // 澳门优才
                    {
                        path: "MacaoGifted/:id",
                        name: "MacaoGiftedDetails",
                        component: () => import("../views/Info/MacaoGifted/MacaoGifted.vue")
                    },
                    // 随迁人员
                    {
                        path: "TransferDetails/:id",
                        name: "TransferDetails",
                        component: () => import("../views/Info/Transfer/Transfer.vue")
                    },
                ]
            },
            // 服务协议
            {
                path: "/Agreement",
                name: "Agreement",
                component: () => import("@/views/Agreement/Agreement.vue")
            },
        ]
    },
    // 服务端
    {
        path: "/EditEntrance",
        name: "EditEntrance",
        component: () => import("../views/EditEntrance/EditEntrance.vue"),
        children: [
            // 澳门优才
            {
                path: "MacaoGifted/:id",
                name: "MacaoGiftedEdit",
                component: () => import("../views/EditEntrance/MacaoGifted/MacaoGifted.vue")
            },
            // 优才
            {
                path: "Gifted/:id",
                name: "GiftedEdit",
                component: () => import("../views/EditEntrance/Gifted/Gifted.vue")
            },
            // 高才
            {
                path: "HighTalent/:id",
                name: "HighTalent",
                component: () => import("../views/EditEntrance/HighTalent/HighTalent.vue")
            },
            // 随迁人员
            {
                path: "Transfer/:id",
                name: "TransferEdit",
                component: () => import("../views/EditEntrance/Transfer/Transfer.vue")
            },
            // 重办签证
            {
                path: "Visa/:id",
                name: "VisaEdit",
                component: () => import("../views/EditEntrance/Visa/Visa.vue")
            },
        ]
    },
    // 旧客户端嵌入信息页
    {
        path: "/MiddleInfo",
        name: "MiddleInfo",
        component: () => import("../views/MiddleInfo/MiddleInfo.vue"),
        children: [
            // 优才
            {
                path: "Gifted/:id",
                name: "MiddleGifted",
                component: () => import("../views/MiddleInfo/Gifted/Gifted.vue")
            },
            // 澳门优才
            {
                path: "MacaoGifted/:id",
                name: "MiddleMacaoGifted",
                component: () => import("../views/MiddleInfo/MacaoGifted/Gifted.vue")
            },
            // 随迁人员
            {
                path: "Transfer/:id",
                name: "Transfer",
                component: () => import("../views/MiddleInfo/Transfer/Transfer.vue")
            },
        ]
    },
    // 优才方案审核表单
    {
        path: "/SchemeReview",
        name: "SchemeReview",
        component: () => import("../views/SchemeReview/SchemeReview.vue"),
        children: [
            // 优才
            {
                path: "Gifted/:id",
                name: "SchemeReviewGifted",
                component: () => import("../views/SchemeReview/Gifted/Gifted.vue")
            },
        ]
    },

    // 404
    {
        path: '*',
        name: 'ErrorPage',
        component: () => import(/* webpackChunkName: 'ErrorPage' */ '../views/ErrorPage.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

//路由拦截
router.beforeEach((to, from, next) => {
    // 访问客户端时且是由验证登录的
    if (to.path.includes('/UserInfo/') && sessionStorage.getItem('value')) {
        // 访问统计
        sendMonitorData({
            type: 10,
            pathname: to.path,
            value: sessionStorage.getItem('value'),
        })
    }

    return next();
})

export default router
