
import $dayjs from 'dayjs'

// 性别
const sex = (v) => {
    switch (v) {
        case 1:
            return '男'
        case 2:
            return '女'
        default:
            return '未知'
    }
}

// 格式化日期 2020-12-12 -> 2020年12月12日
const date = (v) => {
    return $dayjs(v).format('YYYY年MM月DD日')
}

// 婚姻状况
const married = (v) => {
    switch (v) {
        case 1:
            return '已婚'
        case 2:
            return '未婚'
        case 3:
            return '离婚'
        case 4:
            return '分居'
        case 5:
            return '丧偶'
        case 6:
            return '事实婚姻'
        case 7:
            return '鳏寡'
        default:
            return ''
    }
}

// 证件格式化
const certificates = (v) => {
    switch (v) {
        case 'cn_identity_card':
            return '中国身份证'
        case 'hk_macao_pass':
            return '港澳通行证'
        case 'passport':
            return '护照'
        default:
            return ''
    }
}

// 护照类型
const passportType = (v) => {
    switch (v) {
        case 3:
            return '护照'
        case 4:
            return '外交护照'
        case 5:
            return '公务护照'
        case 6:
            return '中国发无国籍旅游证件'
        case 7:
            return '旅游通行证'
        case 8:
            return '联合国通行证'
        case 9:
            return '其他无国籍旅行证件'
        case 10:
            return '其他'
        default:
            return '-'
    }
}

export {
    sex,
    date,
    married,
    certificates,
    passportType,
}
