export default {
    namespaced: true,
    state: {
        // 主申请人
        user: {},
        // 家庭成员
        family: {
            // 父母亲 数组0父亲，1母亲
            parent: [],
            spouse: {},
            children:[],
            brothers:[]
        },
        //资产
        property:{},
        //基本资料
        applicant:{},
        //教育经历
        education:[],
        //工作经验
        works:[],
        //居住信息
        residences:[],
        //专业技能及行业
        profession:{},
        //语言能力
        language:{}
    },
    mutations: {
        updateData(state, data) {
            state[data.key] = data.data
        },
        // 家庭成员数组处理
        updateFamilyArr(state, data) {
            state.family[data.key] = data.data
        }
    },
    getters: {

    },
    actions: {
        setStateKeyData({ commit }, data) {
            commit('updateData', data)
        },
        // 家庭成员数组处理
        setFamilyKeyArr({ commit }, data) {
            commit('updateFamilyArr', data)
        }
    }
}
