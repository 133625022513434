<!--
 * @Author: Jacob.Zhang
 * @Description: 国内外地址选择组件
 * @example <AddressSelect :addressData="addressData" @handleData="getData" />
 * @props ['广东', '深圳']
-->
<template>
    <div>
        <el-radio-group v-model="addressData.aboard" @change="changeCountry">
            <!-- 国内0 国外1 -->
            <el-radio :label="0">国内</el-radio>
            <el-radio :label="1">国外</el-radio>
        </el-radio-group>
        <!-- 国内下拉框 -->
        <CitySelect v-if="addressData.aboard === 0" v-model="addressData.city" />
        <!-- 国外输入框 -->
        <el-input
            v-if="addressData.aboard === 1"
            v-model.trim="addressData.foreignPlace"
            style="width:190px;" maxlength="20" placeholder="请输入"
            @blur="changeCountry"
        />
    </div>
</template>

<script>
export default {
    name: 'AddressSelect',
    props: {
        addressData: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    watch: {
        'addressData.city'() {
            this.changeCountry()
        },
    },
    methods: {
        changeCountry() {
            // 国内
            if(this.addressData.aboard === 0) this.$emit("handleData", {
                aboard: this.addressData.aboard,
                city: this.addressData.city,
            })
            // 国外
            if(this.addressData.aboard === 1) this.$emit("handleData", {
                aboard: this.addressData.aboard,
                foreignPlace: this.addressData.foreignPlace,
            })
        },
    }
}
</script>

<style scoped lang="less">
.el-radio:not(:last-of-type) {
    margin-right: 12px;
}
.el-radio:last-of-type {
    margin-right: 10px;
}
</style>
