import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import dayjs from 'dayjs'
// import OSS from 'ali-oss'
import rules from './utils/rules'
import  * as utils  from './utils/utils'
import directives from './directives';
import components from './components';
import config from './config';
import * as filters from './filters/filters';
import awesome from "./assets/font-awesome/css/font-awesome.min.css"
Vue.config.productionTip = false
// sentry接入
import { initSentry } from '@/utils/sentry';

// 全局设置UI组件尺寸
Vue.use(ElementUI, {
    size: 'small'
});

// 全局过滤器
Object.keys(filters).forEach(key => {
    Vue.filter('_' + key, filters[key])
});
// 全局指令
Vue.use(directives);
// 全局组件
Vue.use(components);
//  图标库
Vue.use(awesome)
// 获取osskey
// utils.getOssKey(Vue)
// 生存环境神策埋点
utils.sensorsData(Vue)


// 挂载一些公共方法
Vue.prototype.$rules = rules;
Vue.prototype.$utils = utils;
Vue.prototype.$dayjs = dayjs;
Vue.prototype.$CONFIG = config;

/* eslint-disable */
// Vue.prototype.$OSS = new OSS({
//     region: 'oss-cn-shenzhen',  //bucket 所在的区域, 默认 oss-cn-hangzhou
//     accessKeyId: 'LTAI4G23YzQkpcybpJwSnPSk',
//     secure: true,
//     accessKeySecret: '6usCjfvTnY69camad9nynVzkArSGLV',
//     bucket: 'galaxy-immi-mp'
// });
/* eslint-disable */

if (process.env.VUE_APP_ENV == 'production') {
    initSentry(Vue, router);
}


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
