/**
 * @description 全局el-input只能输入正整数
 * @author filename
 * @example <el-input v-model="inputValue" v-positiveInteger />
 */

export default {
    bind(el) {
        el.handler = function (e) {
            // 使用正则表达式确保只能输入数字
            e.target.value = e.target.value.replace(/[^\d]/g, '');
        };
        el.addEventListener('input', el.handler);
    },
    unbind(el) {
        el.removeEventListener('input', el.handler);
    },
};
